import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()

import 'stylesheets/lambethsendiass/application.scss'

import 'javascripts/navbar'
import 'javascripts/cms_pages'
import 'javascripts/blog_article_browser'
import 'javascripts/site_searches'
import 'javascripts/glide'
import 'javascripts/documents'

import 'javascripts/was_this_useful'

